import { getCookie, setCookie, deleteCookie } from "cookies-next";
import { CookieValueTypes } from "cookies-next/lib/types";
import { IncomingMessage, ServerResponse } from "http";
import { Session, StorageType, VisitorInfo } from "./sharedTypes";
export const setAccessToken = (
    accessToken: string | null,
    req?:
        | (IncomingMessage & {
              cookies: Partial<{
                  [key: string]: string;
              }>;
          })
        | undefined,
    res?: ServerResponse,
): void => {
    if (accessToken) {
        const cookieHoursLifeTime = process.env
            .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
            ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
            : 1;

        setCookie(`_tf_token`, accessToken, {
            req,
            res,
            maxAge: 60 * 60 * cookieHoursLifeTime,
        });
    }
};

export const setLocaleCookie = (
    locale: string,
    req?:
        | (IncomingMessage & {
              cookies: Partial<{
                  [key: string]: string;
              }>;
          })
        | undefined,
    res?: ServerResponse,
): void => {
    setCookie(`_tf_locale`, locale, {
        req,
        res,
    });
};

export const getLocale = (
    req?:
        | (IncomingMessage & {
              cookies: Partial<{
                  [key: string]: string;
              }>;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const locale = getCookie("_tf_locale", { req, res });
    return locale;
};

export const setVisitorId = (
    visitorId: string | null,
    req:
        | (IncomingMessage & {
              cookies: Partial<{
                  [key: string]: string;
              }>;
          })
        | undefined,
    res: ServerResponse,
): void => {
    if (visitorId) {
        const cookieHoursLifeTime = process.env
            .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
            ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
            : 1;

        setCookie(`_tf_visitorId`, visitorId, {
            req,
            res,
            maxAge: 60 * 60 * cookieHoursLifeTime,
        });
    }
};

export const setVisitId = (
    visitId: string | null,
    req:
        | (IncomingMessage & {
              cookies: Partial<{
                  [key: string]: string;
              }>;
          })
        | undefined,
    res: ServerResponse,
): void => {
    if (visitId) {
        const cookieHoursLifeTime = process.env
            .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
            ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
            : 1;

        setCookie(`_tf_visitId`, visitId, {
            req,
            res,
            maxAge: 60 * 60 * cookieHoursLifeTime,
        });
    }
};

export const setAffiliateListIdCookie = (
    affiliateListId: string | number | null,
    req:
        | (IncomingMessage & {
              cookies: Partial<{
                  [key: string]: string;
              }>;
          })
        | undefined,
    res: ServerResponse | undefined,
): void => {
    if (affiliateListId) {
        setCookie(`_affiliateListReqId`, affiliateListId, {
            req,
            res,
        });
    } else {
        deleteCookie(`_affiliateListReqId`, { req, res });
    }
};

export const setListIdCookie = (
    listId: string | number | null,
    req:
        | (IncomingMessage & {
              cookies: Partial<{
                  [key: string]: string;
              }>;
          })
        | undefined,
    res: ServerResponse | undefined,
): void => {
    if (listId) {
        setCookie(`_listReqId`, listId, {
            req,
            res,
        });
    } else {
        deleteCookie(`_listReqId`, { req, res });
    }
};

export const getAccessToken = (
    req?: IncomingMessage & {
        cookies: Partial<{
            [key: string]: string;
        }>;
    },
    res?: ServerResponse,
): CookieValueTypes => {
    const accessToken = getCookie("_tf_token", { req, res });
    return accessToken;
};

export const getVisitorId = (
    req?: IncomingMessage & {
        cookies: Partial<{
            [key: string]: string;
        }>;
    },
    res?: ServerResponse,
): CookieValueTypes => {
    const visitorId = getCookie("_tf_visitorId", { req, res });
    return visitorId;
};

export const getVisitId = (
    req?: IncomingMessage & {
        cookies: Partial<{
            [key: string]: string;
        }>;
    },
    res?: ServerResponse,
): CookieValueTypes => {
    const visitId = getCookie("_tf_visitId", { req, res });
    return visitId;
};

export const getListId = (
    req?: IncomingMessage & {
        cookies: Partial<{
            [key: string]: string;
        }>;
    },
    res?: ServerResponse,
): CookieValueTypes => {
    const listId = getCookie("_listReqId", { req, res });
    return listId;
};

export const getAffiliateListId = (
    req?: IncomingMessage & {
        cookies: Partial<{
            [key: string]: string;
        }>;
    },
    res?: ServerResponse,
): CookieValueTypes => {
    const affiliateListId = getCookie("_affiliateListReqId", { req, res });
    return affiliateListId;
};

export function saveToStorage(
    storageType: StorageType,
    key: string,
    value: string,
) {
    if (storageType === "local" && key && value) {
        try {
            localStorage?.setItem(key, value);
        } catch (error) {
            return;
        }
    } else if (storageType === "session" && key && value) {
        try {
            sessionStorage?.setItem(key, value);
        } catch (error) {
            return;
        }
    }
}

export function getFromStorage(storageType: StorageType, key: string) {
    try {
        if (storageType === "local" && key) {
            const data = localStorage?.getItem(key);
            if (data && data !== "" && data.length) {
                return data;
            }
        } else if (storageType === "session" && key) {
            const data = sessionStorage?.getItem(key);
            if (data && data !== "" && data.length) {
                return data;
            }
        }
        return "";
    } catch (error) {
        return "";
    }
}
export const setVisitorInfo = (session: Session): void => {
    const userInfo = {
        isBot:
            session?.visitDetails?.visitId === "bot-visit" ||
            session?.visitDetails?.visitorId === "bot-visitor",
        token: session?.accessToken as string,
        country: session?.visitDetails.country,
        region: session?.visitDetails.region,
    };
    saveToStorage("session", "visitorInfo", JSON.stringify(userInfo));
};

export const getVisitorInfo = (): VisitorInfo => {
    try {
        const userInfo = getFromStorage("session", "visitorInfo");
        if (userInfo && userInfo.length && userInfo.length > 0) {
            const parsedUserInfo: VisitorInfo = JSON.parse(
                userInfo,
            ) as VisitorInfo;
            return parsedUserInfo;
        }
        return {
            token: "",
            country: "",
            region: "",
            isBot: false,
        };
    } catch (error) {
        return {
            token: "",
            country: "",
            region: "",
            isBot: false,
        };
    }
};
