import { replacePlaceholders } from "@lib/sharedUtils";
import { PostType } from "../gqlQueries";
import { DomainInfo } from "./services";
export const getUrl = (pathname: string = "") => {
    return `${window.location.origin}${pathname}`;
};

export const getMainTitlesNavigationFromBody = (html: string) => {
    const regex = /<[^>]+ id="([^"]+)"[^>]*>([\s\S]+?)<\/[^>]+>/g;
    const result = [];

    let match;
    while ((match = regex.exec(html))) {
        const [, id, content] = match;

        const textNodes = content.match(/>([^<]+)/g);

        if (textNodes) {
            result.push(
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...textNodes?.map((node) => ({
                    value: node.substring(1).trim(),
                    id,
                })),
            );
        } else {
            result.push({
                value: content.trim(),
                id,
            });
        }
    }
    return result
        .filter((i) => i.value?.length > 1)
        .map((el) => ({
            title: /^\d/.test(el.value)
                ? el.value.replace(/^\d/, "").slice(1)
                : el.value,
            link: `#${el.id}`,
        }));
};

export const addStyleClassToTable = (body: string) => {
    if (body) {
        // start tag replace with <div className="table-responsive" > <table
        const firstSectionStartTag = body?.split("<table")[0]; // first section dose not contain <table to replace
        const bodyAfterReplaceStartTag = body
            ?.split("<table")
            .slice(1)
            .map((table) => {
                return `<div class="table-responsive"> <table ${table}`;
            });
        // final body after replace start tag
        const bodyAfterAddingStartTag = [
            firstSectionStartTag,
            ...bodyAfterReplaceStartTag,
        ].join(" ");

        // end tag replace with </table> </div>

        const firstSectionEndTag = bodyAfterAddingStartTag.split("</table>")[0];

        const bodyAfterReplaceEndTag = bodyAfterAddingStartTag
            .split("</table>")
            .slice(1)
            .map((table) => {
                return `</table></div>${table}`;
            });
        // final body after replace

        const finalBody = [firstSectionEndTag, ...bodyAfterReplaceEndTag].join(
            " ",
        );
        return finalBody;
    } else {
        return body;
    }
};

export const addStyleClassToIframe = (body: string) => {
    if (body) {
        // start tag replace with <div className="iframe-responsive" > <iframe
        const firstSectionStartTag = body?.split("<iframe")[0]; // first section dose not contain <iframe to replace
        const bodyAfterReplaceStartTag = body
            ?.split("<iframe")
            .slice(1)
            .map((iframe) => {
                return `<div class="iframe-responsive"> <iframe ${iframe}`;
            });
        // final body after replace start tag
        const bodyAfterAddingStartTag = [
            firstSectionStartTag,
            ...bodyAfterReplaceStartTag,
        ].join(" ");

        // end tag replace with </iframe> </div>

        const firstSectionEndTag =
            bodyAfterAddingStartTag.split("</iframe>")[0];

        const bodyAfterReplaceEndTag = bodyAfterAddingStartTag
            .split("</iframe>")
            .slice(1)
            .map((iframe) => {
                return `</iframe></div>${iframe}`;
            });
        // final body after replace

        const finalBody = [firstSectionEndTag, ...bodyAfterReplaceEndTag].join(
            " ",
        );
        return finalBody;
    } else {
        return body;
    }
};

export const getFormattedDate = (date: string) => {
    const regex = /^[0-9]+$/; //check if the string contain only numeric characters(time stamp)

    return new Date(regex.test(date) ? +date : date).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};
export const extractExcerptFromBody = (body: string, characters: number) => {
    return body
        .replace(/<[^>]+>/g, "")
        .substring(0, characters)
        .trim();
};
export const getDescriptionForPostByID = (post: PostType) => {
    if (post && post?.metatags && post?.metatags?.length) {
        for (let i = 0; i < post?.metatags.length; i++) {
            if (
                post.metatags[i]?.key === "description" &&
                post.metatags[i]?.value
            ) {
                return post?.metatags[i]?.value;
            }
        }
    }

    if (post?.excerpt) {
        return extractExcerptFromBody(
            replacePlaceholders(post.excerpt, {
                category: post?.category?.label ?? post?.category?.name,
            }),
            160,
        );
    }
    if (post?.body) {
        return extractExcerptFromBody(
            replacePlaceholders(post.body, {
                category: post?.category?.label ?? post?.category?.name,
            }),
            160,
        );
    }

    return "Several Brands LLC is a Performance Marketer Firm. We generate leads, calls and live transfers for local businesses. Furthermore, we promote various products from several affiliate networks.";
};
export const getKeyWordsForPostByID = (
    post: PostType,
    domainInfo: DomainInfo,
) => {
    if (post && post?.metatags && post?.metatags?.length) {
        for (let i = 0; i < post?.metatags.length; i++) {
            if (
                post?.metatags[i]?.key === "keywords" &&
                post?.metatags[i]?.value
            ) {
                return post?.metatags[i]?.value;
            }
        }
    }

    if (post?.tags) {
        return post?.tags?.map((tag) => tag?.name).join(",");
    }
    for (let i = 0; i < (domainInfo?.metatags.length ?? 0); i++) {
        if (
            domainInfo?.metatags[i]?.key === "keywords" &&
            domainInfo?.metatags[i]?.value
        ) {
            return domainInfo?.metatags[i]?.value;
        }
    }

    return "";
};

export const getDescriptionForHomePage = (domain: DomainInfo) => {
    if (domain && domain?.metatags && domain?.metatags?.length) {
        for (let i = 0; i < (domain?.metatags?.length ?? 0); i++) {
            if (
                domain?.metatags[i]?.key === "description" &&
                domain?.metatags[i]?.value
            ) {
                return domain?.metatags[i]?.value;
            }
        }
    }

    if (domain?.description) {
        return domain.description.replace(/<[^>]+>/g, "");
    }

    return "Several Brands LLC is a Performance Marketer Firm. We generate leads, calls and live transfers for local businesses. Furthermore, we promote various products from several affiliate networks.";
};
export const getKeyWordsForMetaTags = (domain: DomainInfo) => {
    if (domain && domain?.metatags && domain?.metatags?.length) {
        for (let i = 0; i < (domain?.metatags?.length ?? 0); i++) {
            if (
                domain?.metatags[i]?.key === "keywords" &&
                domain?.metatags[i]?.value
            ) {
                return domain?.metatags[i]?.value;
            }
        }
    }
    return "";
};

export const getMetaTitleDescriptionForPage = (
    domain: DomainInfo,
    keyPage: string,
) => {
    if (domain && domain?.metatags && domain?.metatags?.length) {
        return {
            title: domain?.metatags.find(
                (metaTag) => metaTag.key === `extra-meta-${keyPage}-meta-title`,
            )?.value,
            description: domain?.metatags.find(
                (metaTag) =>
                    metaTag.key === `extra-meta-${keyPage}-meta-description`,
            )?.value,
        };
    }
};

export const getFinalLogosForLogoSLider = (
    featuredList:
        | { [key: string]: { path: string; slug: string }[] }
        | undefined
        | null,
    categorySlug: string | undefined | null,
) => {
    const finalLogos = featuredList
        ? categorySlug
            ? featuredList[categorySlug]
            : featuredList["default"]
        : [];
    return finalLogos;
};
